<script lang="ts" setup>
import type { VMenu } from 'vuetify/components/VMenu'

export interface MenuItem {
  label: string
  icon: string
  action: () => void
  isHidden?: boolean
  isLoading?: boolean
  color?: 'text' | 'primary' | 'success' | 'warning' | 'danger'
}

const { location = 'bottom right' } = defineProps<{
  items: MenuItem[]
  dense?: boolean
  invisible?: boolean
  location?: VMenu['$props']['location']
}>()

const onClick = (event: MouseEvent, item: MenuItem) => {
  if ('isLoading' in item) {
    // We want to prevent the menu from closing if an action can cause a loading state
    event.stopPropagation()
  }
  item.action()
}
</script>

<template>
  <v-menu v-if="!items.every(item => item.isHidden)" :location="location" content-class="skr-context-menu">
    <template #activator="{ props }">
      <v-btn
        data-cy="context_menu_button"
        class="menu-activator"
        :class="{ invisible: invisible }"
        variant="text"
        icon
        v-bind="props"
      >
        <v-icon color="text">custom:more_vert</v-icon>
      </v-btn>
    </template>
    <v-list class="pa-1" :density="dense ? 'compact' : undefined">
      <template v-for="item in items">
        <v-list-item
          v-if="!item.isHidden"
          :key="item.label"
          min-height="48"
          :class="['menu-item', `menu-item--${item.color ?? 'primary'}`]"
          :disabled="item.isLoading"
          @click="onClick($event, item)"
        >
          <template #prepend>
            <v-progress-circular v-if="item.isLoading" size="24" indeterminate />
            <v-icon v-else>{{ item.icon }}</v-icon>
          </template>

          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<style lang="sass">
.skr-context-menu
  $colorMap: ('text': $c-text, 'primary': $c-primary, 'success': $c-success, 'warning': $c-warning, 'danger': $c-danger)
  .menu-item
    grid-template-columns: 40px 1fr auto
    border-radius: $bw
    cursor: pointer
    font-weight: bold
    color: $c-text
    @each $color, $value in $colorMap
      &--#{$color}
        &:hover
          color: $value !important
          background-color: rgba($value, 0.12) !important
          *
            color: $value !important
</style>
